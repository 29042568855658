import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

import styles from 'styles/RouterProgress.module.css'

const RouteChangeProgress = () => {
    const router = useRouter()
    const [progress, setProgress] = useState(0)

    useEffect(() => {
        let timeout

        const complete = () => {
            clearTimeout(timeout)
            setProgress(99)
            timeout = setTimeout(() => setProgress(100), 250)
        }

        const increment = () => {
            const time = Math.round(Math.random() * 250)
            setProgress((progress) => {
                const percent = Math.round(Math.random() * 10)
                const next = Math.min(progress + percent, 90)
                if (next < 90) {
                    timeout = setTimeout(increment, time)
                    return next
                }
                return progress
            })
        }

        const start = () => {
            setProgress(1)
            increment()
        }

        router.events.on('routeChangeStart', start)
        router.events.on('routeChangeComplete', complete)
        router.events.on('routeChangeError', complete)

        return () => {
            clearTimeout(timeout)
            router.events.off('routeChangeStart')
            router.events.off('routeChangeComplete')
            router.events.off('routeChangeError')
        }
    }, [])

    const shouldShow = progress > 0 && progress < 100
    return shouldShow
        ? (
            <div className={styles.wrap}>
                <div className={styles.bar} style={{ width: `${progress}%` }}/>
            </div>
          )

        : null

}

export default RouteChangeProgress