import { createContext, useContext, useState } from 'react'

const SetTranslationsContext = createContext(() => ({}))
const GetTranslationsContext = createContext({})
const TranslationContextProvider = ({ children }) => {
    const [t, setT] = useState({ "": "pl" })
    return (
        <SetTranslationsContext.Provider value={setT}>
            <GetTranslationsContext.Provider value={t}>
                {children}
            </GetTranslationsContext.Provider>
        </SetTranslationsContext.Provider>
    )
}

export const useSetTranslation = () => {
    return useContext(SetTranslationsContext)
}

export const useTranslation = () => {
    return useContext(GetTranslationsContext)
}

export default TranslationContextProvider



