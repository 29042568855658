import { useState, useEffect } from 'react'

const useWindowScroll = () => {
    const [y, setY] = useState(0)
    const [lastY, setLastY] = useState(0)

    useEffect(() => {
        const setScroll = () => {
            if (window.scrollY !== y) {
                setLastY(y)
                setY(window.scrollY)
            }
        }

        setScroll()
        window.addEventListener('scroll', setScroll)

        return () => window.removeEventListener('scroll', setScroll)
    }, [y])

    return [y, lastY]
}

export default useWindowScroll