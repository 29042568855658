const RELOAD_ON_STALE_CACHES = true

// should be first distributed to all cached instances:
export default function unregister (RELOAD_PAGE_AFTER = false) {
    if (typeof navigator === 'undefined') return
    if ('serviceWorker' in navigator) {
        navigator
            .serviceWorker
            .getRegistrations()
            .then(async registrations => {
                const workerCount = registrations.length
                if (workerCount === 0) return

                // clear previous caches:
                await caches
                    .keys()
                    .then(async cacheNames => {
                        const cacheCount = cacheNames.length
                        if (cacheCount > 0 && RELOAD_ON_STALE_CACHES) {
                            RELOAD_PAGE_AFTER = true
                        }
                        for (let i = 0; i < cacheCount; i++) {
                            await caches.delete(cacheNames[i])
                        }
                    })

                // unregister workers:
                const messages = []
                for (let i = 0; i < workerCount; i++) {
                    const worker = registrations[i]
                    await worker.unregister().then(success => {
                        if (success) {
                            messages.push('unregistered service worker.')
                        } else {
                            messages.push('error unregistering service worker.')
                        }
                    })
                }

                // reload page or log:
                if (RELOAD_PAGE_AFTER) {
                    window.location.reload()
                } else {
                    console.log('sw', messages)
                }
            })
    }
}