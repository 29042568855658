import { useCallback } from 'react'
import Icon from '@components/shared/Icon'
import useWindowScroll from '@hooks/useWindowScroll'

import styles from 'styles/ToTop.module.css'

const ToTop = () => {
    const [y] = useWindowScroll()
    const shouldShow = y > 300
    const goUp = useCallback(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }, [])

    return shouldShow
        ? <div className={styles.totop} onClick={goUp}>
            <Icon>keyboard_capslock</Icon>
          </div>

        : null
}

export default ToTop