const areDifferentObjects = (object1 = {}, object2 = {}) => {
    const keys1 = Object.keys(object1)
    const keys2 = Object.keys(object2)

    if (keys1.length !== keys2.length) return true
    for (const key of keys1) {
        if (object1[key] !== object2[key]) return true
    }

    return false
}

export default areDifferentObjects