const MESSAGE_TIME = 7000
const MESSAGE_MARGIN = 20

function setOffsetTop (message) {
	const messages = document.querySelectorAll('.flash-message')
	const mcount = messages.length

	if (mcount) {
		const mostRecent = messages[mcount - 1]
		const topPosition = mostRecent.offsetTop
		const height = mostRecent.offsetHeight

	    message.style.top = topPosition + height + MESSAGE_MARGIN + 'px'
	}

	return message
}

function shiftMessagesUp () {
	let messages = document.querySelectorAll('.flash-message')
	for (let i = 0, l = messages.length; i < l; i++) {
		let otherMessage = messages[i]
		let otherMessageTop = otherMessage.offsetTop
		let height = otherMessage.offsetHeight

		if (otherMessageTop > 100) {
			otherMessage.style.top = otherMessageTop - height - MESSAGE_MARGIN + 'px'
		}
	}
}

const displayMessage = (text, className) => {
	const message = setOffsetTop(document.createElement('div'))

	message.innerText = text
	message.className = 'flash-message' + (className ? ` ${className}` : '')
	message.style.opacity = 0

	let closeButton = document.createElement('i')
	let closeButtonFunction = () => {
		closeButton.onclick = null
		document.body.removeChild(message)
	}

	closeButton.className = 'material-icons clickable'
	closeButton.innerText = 'close'
	closeButton.onclick = closeButtonFunction

	message.append(closeButton)
	document.body.append(message)

	setTimeout(() => message.style.opacity = 1, 300)
	setTimeout(() => message.style.opacity = 0, MESSAGE_TIME + 300)
	setTimeout(() => {
		closeButton.onclick = null
		if (message) message.remove()
		shiftMessagesUp()
	}, MESSAGE_TIME + 600)
}

let currTimeout
const MessageHelper = {
	message: text => {
		displayMessage(text, 'message')
	},
	warn: text => {
		displayMessage(text, 'warning')
	},
	error: text => {
		displayMessage(text, 'error')
	},
	summary: ({ messages = [], errors = [], warn = [] }) => {
		messages.forEach(msg => { if (msg) { displayMessage(msg, 'message') }})
		errors.forEach(err => { if (err) { displayMessage(err, 'error') }})
		warn.forEach(war => { if (warn) { displayMessage(war, 'warning') }})
	},

	loadingDialog: text => {
		const dialog = document.querySelector('.loading-dialog')
		if (dialog) {
			clearTimeout(currTimeout)
			document.body.removeChild(dialog)
			shiftMessagesUp()
		}

		if (text) {
			const dialog = setOffsetTop(document.createElement('div'))
			const span = document.createElement('span')
			const icon = document.createElement('i')
			icon.className = 'material-icons anim-rotate'
			icon.innerText = 'sync'
			span.innerText = text
			span.style.marginLeft = '10px'
			dialog.className = 'message loading-dialog flash-message'
			dialog.appendChild(icon)
			dialog.appendChild(span)

			dialog.style.opacity = 0

			document.body.appendChild(dialog)
			currTimeout = setTimeout(() => dialog.style.opacity = 1, 500)
		}
	},
}

export default MessageHelper
