import { useMemo, useEffect } from 'react'
import ReactDOM from 'react-dom'

const Portal = ({ children, className }) => {
    if (typeof window === 'undefined') return null

    const el = useMemo(() => document.createElement('div'), [])
    el.className = className

    useEffect(() => {
        document.body.appendChild(el)
        return () => document.body.removeChild(el)
    }, [el, className])

    return ReactDOM.createPortal(children, el)
}

export default Portal