import { post } from '@api/apiFetcher'
import { useEffect } from 'react'
import useSWR from 'swr'
import storage from '@utils/localStorage'
import checkLogin from '@utils/checkLogin'
import waitForWalletChangeAndMutate from '@utils/user/waitForWalletChangeAndMutate'
import waitForPacketChangeAndMutate from '@utils/user/waitForPacketChangeAndMutate'
import waitForPartnerPacketChangeAndMutate from '@utils/user/waitForPartnerPacketChangeAndMutate'
import useT from '@hooks/useTranslation'

const UserChangeListener = () => {
    if (typeof window === 'undefined') return null

    const { data: user } = useSWR('user', storage)
    const text = {
        walletApprovalPending: useT('Poczekaj aż środki zostaną zaksięgowane') + '...',
        walletApprovalComplete: useT('Sprawdź konto. Środki portfela zostały zaksięgowane') + '.',
        paymentValidation: useT('Trwa walidacja płatności') + '...',
        packetGranted: useT('Pomyślnie przyznano zakupiony pakiet') + '.',
    }

    useEffect(() => {
        const isLoggedIn = checkLogin(user)
        if (isLoggedIn) {
            post({
                path: '/api/user/message',
                data: {},
                auth: user.token })
                    .then(res => {
                        if (res.success) {
                            switch (res.message) {
                                case 'wallet':
                                    waitForWalletChangeAndMutate({
                                        auth: user.token,
                                        compareTo: { wallet: user.wallet },
                                        beginMessage: text.walletApprovalPending,
                                        successMessage: text.walletApprovalComplete,
                                    })

                                case 'offer_packet':
                                    waitForPacketChangeAndMutate({
                                        auth: user.token,
                                        compareTo: { prepaid_ads: user.prepaid_ads },
                                        beginMessage: text.paymentValidation,
                                        successMessage: text.packetGranted,
                                    })
                                    break

                                case 'partner_packet':
                                    waitForPartnerPacketChangeAndMutate({
                                        auth: user.token,
                                        compareTo: { prepaid_items: user.prepaid_items },
                                        beginMessage: text.paymentValidation,
                                        successMessage: text.packetGranted,
                                    })
                                    break

                                default:
                            }
                        }
                    })
        }
    }, [user?._id])

    return null
}

export default UserChangeListener