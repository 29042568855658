import { getLanguage } from '@utils/sessionLanguage'

export const constructAuthHeader = (token) => {
    return ['Authorization', `JWT ${encodeURIComponent(token)}`]
}

export const makeHeaders = (token) => {
    const base = {
        'Content-Type': 'application/json',
        'Content-Language': getLanguage(),
    }

    if (token) {
        const [authHeader, jwt] = constructAuthHeader(token)
        base[authHeader] = jwt
    }

    return base
}

export const get = ({ path, auth = '' }) => {
    return fetch(path, {
        headers: makeHeaders(auth)
    })
    .then(res => res.json())
}

export const post = ({ path, data = '', auth = '' }) => {
    return fetch(path, {
        headers: makeHeaders(auth),
        method: 'POST',
        body: JSON.stringify(data),
    })
    .then(res => res.json())
}

/* SWR functions */

// fetch unprotected:
export const apiFetcher = async (path) => await get({ path })

// avoids cache:
export const apiFetcherPost = async (path) => await post({ path })

// fetch protected:
export const apiFetcherProtected = (auth) => async (path) => await get({ path, auth })

// avoids cache:
export const apiFetcherProtectedPost = (auth) => async (path) => await post({ path, auth })

export default apiFetcher