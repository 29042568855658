import { useRef, useState, useEffect } from 'react'
import Portal from '@components/modal/Portal'
import mix from '@utils/styles/mix'

import styles from 'styles/Modal.module.css'

const Modal = ({ children, open, onClose, locked }) => {
    const [active, setActive] = useState(false)
    const curtain = useRef(null)

    useEffect(() => {
        const { current } = curtain
        const transitionEnd = () => setActive(open)
        const onClick = (e) => !locked && e.target === current && onClose && onClose()
        if (current) {
            current.addEventListener('transitionend', transitionEnd)
            current.addEventListener('click', onClick)
        }

        if (open) {
            window.setTimeout(() => {
                document.activeElement.blur()
                setActive(open)
                document.getElementById('__next').setAttribute('inert', 'true')
            }, 100)
        }

        return () => {
            if (current) {
                current.removeEventListener('transitionend', transitionEnd)
                current.removeEventListener('click', onClick)
            }

            document.getElementById('__next').removeAttribute('inert')
        }
    }, [open, locked, onClose])

    const isActive = active && open
    return <>
        { (open || active) && <Portal className="react-portal">
            <Curtain innerRef={curtain} active={isActive}>
                <Content active={isActive}>{children}</Content>
            </Curtain>
        </Portal> }
    </>
}

function Curtain ({ children, innerRef, active }) {
    return (
        <div ref={innerRef} className={mix([styles.curtain, active && styles.active])}>
            {children}
        </div>
    )
}

function Content ({ children, active }) {
    return (
        <div className={mix([styles.content, active && styles.active])}>
            {children}
        </div>
    )
}

export default Modal