import { mutate } from 'swr'

const mutateUser = (user, assignObject = {}) => {
    if (user) {
        const newUser = Object.assign(user, assignObject)
        window.localStorage.setItem('user', JSON.stringify(newUser))
        mutate('user', newUser)
    }
}

export default mutateUser