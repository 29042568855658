import Head from 'next/head'
import { useRouter } from 'next/router'
import sanitize from '@utils/script/sanitize'

const GA_CODE = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS
const GA_SCRIPT = sanitize(`
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', '${GA_CODE}', {
    page_path: window.location.pathname
  });
`)

const CLARITY_SCRIPT = sanitize(`
  (function(c,l,a,r,i,t,y){ c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)}; t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i; y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y); })(window, document, "clarity", "script", "8kisehkvkg");
`)

const HOST = process.env.NEXT_PUBLIC_HOST
const inProduction = process.env.NODE_ENV === 'production'
const onStage = process.env.NEXT_PUBLIC_STAGE === 'true'

export default function StaticSeo () {
  const router = useRouter()
  const underSubdomain = Boolean(router.query.subdomain)

  return <Head>
    <meta charSet="utf-8" />
    <meta name='application-name' content='dostawczaki.pl' />
    <link rel="shortcut icon" href="/favicon.ico" />
    <link rel="apple-touch-icon" href="/icons/icon-192x192.png" />
    <link rel='manifest' href='/manifest.json' />
    <link rel="preload stylesheet" as="style" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
    <link rel="preload stylesheet" as="style" href="https://fonts.googleapis.com/css?family=Maven+Pro:400,700" />
    <link rel="preload stylesheet" as="style" href="https://fonts.cdnfonts.com/css/bebas-neue?styles=17621,17620" />
    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, shrink-to-fit=no" />
    <meta name="theme-color" content="#000000" />
    <meta property="fb:app_id" content="2561398784175766" />
    <meta property="og:type" content="website" />
    <meta property="og:site_name" content="samochody" />
    <meta property="og:locale" content="pl_PL" />
    <meta name="twitter:site" content="@dostawczaki.pl" />
    <meta name="twitter:creator" content="@dostawczaki.pl" />
    <meta name="twitter:card" content="summary_large_image" />
    <meta key="robots" name="robots" content={onStage ? 'noindex' : 'all'} />

    { !underSubdomain && router.locales.map(locale =>
      <link
        key={locale}
        href={locale === 'pl-PL'
          ? `${HOST}${router.asPath}`
          : `${HOST}/${locale}${router.asPath}`}
        hrefLang={locale}
        rel="alternate" /> )}

    <script
      src={`https://www.googletagmanager.com/gtag/js?id=${GA_CODE}`}
      strategy="lazyOnload" />

    <script
      dangerouslySetInnerHTML={{
        __html: GA_SCRIPT,
      }}
      strategy="lazyOnload" />

    { inProduction && <script
        dangerouslySetInnerHTML={{
          __html: CLARITY_SCRIPT,
        }} /> }

  </Head>
}