import { useCallback, useState } from 'react'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import Modal from '@components/modal/Modal'
import ListOut from '@components/shared/ListOut'
import Button from '@components/shared/NavLinkButton'
import Icon from '@components/shared/Icon'
import useT from '@hooks/useTranslation'
import useCookie from '@hooks/useCookie'
import { PRIVACY_PAGE } from '@constants/routes'

import styles from 'styles/Cookies.module.css'

const DOMAIN = process.env.NEXT_PUBLIC_DOMAIN

const COOKIES = {
    mainDescription: 'Serwis dostawczaki.pl używa ciasteczek niezbędnych do poprawnego funkcjonowania witryny, oraz ciasteczek, które pozwalają śledzić twoje preferencje i sposób, w jaki korzystasz z witryny, aby lepiej dopasowywać usługi do twoich oczekiwań.',
    detailItems: [
        {
            _key: 'A',
            description: 'Są to ciasteczka, bez których serwis nie będzie działał poprawnie. Ich akceptacja jest wymagana. Jeżeli nie zgadzasz się na przechowywanie tych danych, będziesz musiał opuścić serwis:',
            category: 'Wymagane ciasteczka',
            cookieList: [
                {
                    name: 'cook_consent',
                    description: 'Zapamiętuje twoją zgodę na korzystanie z ciasteczek',
                    links: [PRIVACY_PAGE],
                },
                {
                    name: 'cook_data',
                    description: 'Zapamiętuje szczegóły twoich ustawień dotyczących ciasteczek',
                    links: [PRIVACY_PAGE],
                },
                {
                    name: 'samochody_pl_u_.*',
                    description: 'Po zalogowaniu przechowują niektóre detale sesji użytkownika. Nie są to dane osobiste, chociaż w trakcie trwania sesji mogą przechowywać twoją lokalizację. Ciasteczka będą usunięte po wylogowaniu.',
                    links: [PRIVACY_PAGE],
                },
            ],
        },
        {
            _key: 'B',
            description: 'Ciasteczka zbierają informacje jak korzystasz z witryny, jakie podstrony odwiedzasz i które elementy są dla Ciebie najbardziej interesujące. Dane są anonimowe i nie mogą posłużyć do namierzania:',
            category: 'Ciasteczka Google Analytics',
            cookieList: [
                {
                    name: '_ga',
                },
                {
                    name: '_gat',
                },
                {
                    name: '_gid'
                }
            ],
        }
    ],

    VIEWS: {
        MAIN: 'main',
        SETTINGS: 'settings',
    },
}

const DEFAULT_SETTINGS = {}
COOKIES.detailItems.forEach(item => DEFAULT_SETTINGS[item._key] = true)

export default function Cookies () {
    const router = useRouter()
    const [consent, setConsent] = useCookie('cook_consent')
    const [data, setData] = useCookie('cook_data')

    const consentAll = useCallback(() => {
        setConsent(true)
        setData(JSON.stringify(DEFAULT_SETTINGS))
        router.push(router.asPath)
    }, [])

    const consentSome = useCallback((data) => {
        setConsent(true)
        setData(JSON.stringify(data))
        router.push(router.asPath)
    }, [])

    if (location.host !== DOMAIN
     || router.asPath === PRIVACY_PAGE) return null

    return (
        <Modal open={!consent} locked>
            <CookieBox consentAll={consentAll} consentSome={consentSome} data={data} />
        </Modal>
    )
}

function CookieBox (funcsAndSettings) {
    const [view, setView] = useState(COOKIES.VIEWS.MAIN)
    const props = { ...funcsAndSettings, setView }
    switch (view) {
        case COOKIES.VIEWS.MAIN: return <CookieBoxMain {...props} />
        case COOKIES.VIEWS.SETTINGS: return <CookieBoxSettings {...props} />
        default: return null
    }
}

function CookieBoxMain ({ consentAll, setView }) {
    const goToSettings = () => setView(COOKIES.VIEWS.SETTINGS)

    const TEXT = {
        heading: useT('Ciasteczka'),
        mainDescription: useT(COOKIES.mainDescription),
        privacy: useT('Więcej dowiesz się na stronie naszej'),
        privacyLink: useT('Polityki Prywatności'),
        consentToAll: useT('Zgoda na wszystnie ciasteczka'),
        goToSettings: useT('Zobacz szczegóły'),
    }

    return <div className={styles.box}>
        <h2><Icon>cookie</Icon> {TEXT.heading}</h2>

        <p>{TEXT.mainDescription}</p>
        <p>{TEXT.privacy} <Link href={PRIVACY_PAGE}><a>{TEXT.privacyLink}</a></Link></p>

        <nav>
            <Button onClick={consentAll} color="teal">
                {TEXT.consentToAll}
            </Button>

            <Button onClick={goToSettings} color="blue">
                {TEXT.goToSettings}
            </Button>
        </nav>
    </div>
}

function CookieBoxSettings ({ consentSome, data, setView }) {
    const goToMain = () => setView(COOKIES.VIEWS.MAIN)

    const defaultSettings = data
        ? JSON.parse(data)
        : DEFAULT_SETTINGS

    const [settings, setSettings] = useState(defaultSettings)

    const toggle = useCallback((key, checked) => {
        setSettings({ ...settings, [key]: checked })
    }, [settings])

    const saveSettings = useCallback(() => {
        consentSome(settings)
    }, [settings])

    const TEXT = {
        heading: useT('Ustawienia ciasteczek'),
        cancel: useT('Powrót'),
        save: useT('Zapisz'),
    }

    return <div className={styles.box}>
        <h2><Icon>settings</Icon> {TEXT.heading}</h2>
        <br/><br/>

        <ListOut
            Component={CookieBoxDetailAccordeon}
            id="_key"
            items={COOKIES.detailItems}
            settings={settings}
            toggle={toggle} />

        <br/><br/>
        <nav>
            <Button onClick={goToMain} style={{background:'var(--gray)',color:'var(--dark-blue)'}}>
                {TEXT.cancel}
            </Button>

            <Button onClick={saveSettings} color="teal">
                {TEXT.save}
            </Button>
        </nav>
    </div>
}

function CookieBoxDetailAccordeon ({ _key, category, cookieList,
    description, settings, toggle }) {

        const [active, setActive] = useState(false)
        const onChange = ({ target: { checked }}) =>
            toggle(_key, checked)

        const info = () => setActive(!active)

        const TEXT = {
            category: useT(category),
            description: useT(description),
            on: useT('Aktywne'),
            off: useT('Nieaktywne'),
        }

        const icon = active ? 'expand_more' : 'chevron_right'
        const checked = settings[_key]
        return (
            <div className={styles.detail}>
                <div className={styles['detail-inner']}>
                    <b onClick={info}><Icon>{icon}</Icon> {TEXT.category}</b>
                    <ToggleInput
                        checked={checked}
                        disabled={_key === 'A'}
                        onChange={onChange}
                        title={checked ? TEXT.on : TEXT.off} />
                </div>

                { active && <>
                    <p>{TEXT.description}</p>
                    <ListOut Component={CookieInfo} id="name" items={cookieList} />
                </> }
            </div>
        )
}

function CookieInfo ({ name, description, links }) {
    const read = useT('więcej') + '...'
    return <>
        <div className={styles.cookie}>
            { name && <b>{name}</b> }
            { description && <p>
                {useT(description)} { links && links.map(url => (
                    <Link key={url} href={url}><a>{read}</a></Link>
                ))}
            </p>}
        </div>

    </>
}

function ToggleInput ({ title, ...props }) {
    return <label className={styles['toggle-input']} title={title}>
        <input type="checkbox" {...props} />
        <span></span>
    </label>
}

const DynamicCookies = dynamic(
    () => import('@components/Cookies'),
    { ssr: false }
)

export const CookieConsent = (props) => <DynamicCookies {...props} />