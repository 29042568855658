const DAY = 24 * 60 * 60
const useCookie = (name) => {
    if (typeof window === 'undefined') return []

    const setCookie = (value = '', expiresInDays = 31 * 6) => {
        document.cookie = `${name}=${value}; max-age=${DAY * expiresInDays}; path=/`
    }

    const deleteCookie = () => {
        document.cookie = name + '=; path=/; max-age=0;'
    }

    let cookie
    for (const ca of document.cookie.split(';')) {
        const c = ca.trim()
        if (c.startsWith(name)) {
            cookie = c.slice(name.length + 1)
        }
    }

    return [cookie, setCookie, deleteCookie]
}

export default useCookie